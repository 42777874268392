<!--能效管理--能效KPI设置-->
<template>
    <div id="analyseKPISet" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <div class="page-title">
            <my-PageTitle>{{ $t('analyseKPISet.a1') }}</my-PageTitle>
        </div>
        <!-- 内容区域 -->
        <div class="content">
            <div class="sider">
                <a-input-search style="margin-bottom: 8px" :placeholder="$t('analyseKPISet.a2')" @change="onChange" />
                <div class="menu">
                    <a-menu v-model="menuSelect" mode="inline">
                        <a-menu-item v-for="item in pointList" :key="item.no" @click="getSignalPointData(item)">
                            {{ item.name }}                        
                        </a-menu-item>
                    </a-menu>
                </div>
            </div>
            <div class="main">
                <!-- 查询栏 -->
                <div class="query-bar">
                    <div class="dateSelect">
                        <!-- 年、月、日类型选择 -->
                        <a-select v-model="dateType" style="width: 80px;margin-right:15px">
                            <a-select-option v-for="item in rptKinds" :value="item.no" :key="item.no">
                                {{ item.text }}
                            </a-select-option>
                        </a-select>
                        <div class="dateType">
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateType == '1'">
                                    <!-- 年份选择 -->
                                    <span class="date-select-font">{{ $t('analyseKPISet.a3') }}</span>
                                    <a-select v-model="fiscalYearSelect" style="width: 120px;margin-right:15px">
                                        <a-select-option v-for="item in yearData" :value="item.value" :key="item.value">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                    <span class="date-select-font">{{ $t('analyseKPISet.a4') }}</span>
                                    <a-select v-model="yearRuleSelect" style="width: 130px;margin-right:15px">
                                        <a-select-option v-for="item in yearRuleData" :value="item.no" :key="item.no">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </transition>
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateType == '2'" >
                                    <!-- 年、月选择 -->
                                    <span class="dateFont">{{ $t('analyseKPISet.a3') }}</span>
                                    <a-select v-model="monthFiscalYear" style="width: 100px;margin-right:15px">
                                        <a-select-option v-for="item in yearData" :value="item.value" :key="item.value">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                    <span class="dateFont">{{ $t('analyseKPISet.a5') }}</span>
                                    <a-select v-model="comparisonFiscalYear" style="width: 100px;margin-right:15px">
                                        <a-select-option v-for="item in yearData" :value="item.value" :key="item.value" :disabled="item.value == monthFiscalYear">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                    <span class="date-select-font">{{ $t('analyseKPISet.a4') }}</span>
                                    <a-select v-model="yearRuleSelect" style="width: 130px;margin-right:15px">
                                        <a-select-option v-for="item in yearRuleData" :value="item.no" :key="item.no">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </transition>
                            <transition name="slide-fade">
                                <div class="date-select" v-if="dateType == '3'">
                                    <!-- 年、月、日选择 -->
                                    <span class="dateFont">{{ $t('analyseKPISet.a6') }}</span>
                                    <a-month-picker v-model="month" placeholder="Select month" style="width: 100px;margin-right:15px"/>
                                    <span class="dateFont">{{ $t('analyseKPISet.a7') }}</span>
                                    <a-month-picker v-model="comparisonMonth" placeholder="Select month" style="width: 100px;margin-right:15px"/>
                                    <span class="date-select-font">{{ $t('analyseKPISet.a4') }}</span>
                                    <a-select v-model="yearRuleSelect" style="width: 130px;margin-right:15px">
                                        <a-select-option v-for="item in yearRuleData" :value="item.no" :key="item.no">
                                            {{ item.name }}
                                        </a-select-option>
                                    </a-select>
                                    <a-select v-model="strategy" style="width: 140px">
                                        <a-select-option :value="99">
                                            {{ $t('energy.placeSelect') }}
                                        </a-select-option>
                                        <a-select-option :value="0">
                                            {{ $t('analyseKPISet.a8') }}
                                        </a-select-option>
                                        <a-select-option :value="1">
                                            {{ $t('analyseKPISet.a9') }}
                                        </a-select-option>
                                        <a-select-option :value="2">
                                            {{ $t('analyseKPISet.a10') }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div>
                        <a-button type="primary" ghost @click="dataQuery">{{ $t('analyseKPISet.a11') }}</a-button>
                    </div>
                </div>
                <!-- 表格数据 -->
                <div class="tableDataSet">
                    <!-- 年类型表格 -->
                    <div v-show="dateType == '1'">
                        <a-table 
                            :columns="yearColumns" 
                            :data-source="yearTableData" 
                            :pagination="false" size="middle" 
                            bordered 
                            :rowKey="(record) => record.key"
                        >
                            <template slot="reductionAim" slot-scope="text, record">
                                    <!-- :min="0" 
                                    :max="100"  -->
                                <a-input 
                                    v-model="record.reductionAim"
                                    v-if="record.dateTime == fiscalYearSelect" 
                                    :placeholder="$t('analyseKPISet.a55')"
                                    style="width:100px"
                                    @input.native="(e)=>reductionAimValue(e,record)"
                                />
                                <span v-else >{{ record.reductionAim }}</span>
                            </template>
                            <template slot="aimValue" slot-scope="text, record">
                                    <!-- :min="0"  -->
                                <a-input 
                                    v-model="record.aimValue"
                                    v-if="record.dateTime == fiscalYearSelect"  
                                    :placeholder="$t('analyseKPISet.a45')"
                                    style="width:100%"
                                     @input.native="(e)=>aimValueValue(e,record)"
                                />
                                <span v-else >{{ record.aimValue }}</span>
                            </template>
                        </a-table>
                    </div>
                    <!-- 月类型表格 -->
                    <div class="table" v-show="dateType == '2'">
                        <div style="width:55%">
                            <a-table 
                                :columns="monthColumns1" 
                                :data-source="monthData1" 
                                :pagination="false" 
                                size="middle" 
                                bordered 
                                :rowKey="(record) => record.key"
                            >
                                <template slot="aimValue" slot-scope="text, record">
                                    <span style="margin-right:10px">{{ monthFiscalYear }}</span>
                                    <span>{{ $t('analyseKPISet.a12') }}</span>
                                    <span v-if="record.aimValue !== ''">{{ record.aimValue }}</span>
                                    <span v-else>----</span>
                                </template>
                            </a-table>
                            <a-table 
                                :columns="monthColumns2" 
                                :data-source="monthData2" 
                                :pagination="false" 
                                size="middle" 
                                bordered
                                :rowKey="(record) => record.month"
                                ref="table"
                            >
                                <template slot="aimValue" slot-scope="text, record">
                                        <!-- :min="0"  -->
                                    <a-input 
                                        v-model="record.aimValue"
                                        :placeholder="$t('analyseKPISet.a45')"
                                        style="width:130px"
                                        v-if="record.show"
                                        :disabled="record.lock"
                                        @input.native="(e)=>monthTargetValue(e,record)"
                                    />
                                    <span v-else>{{ record.aimValue }}</span>
                                </template>
                                <template slot="rateOfTotal" slot-scope="text, record">
                                        <!-- :min="0" 
                                        :max="100"  -->
                                    <a-input 
                                        v-model="record.rateOfTotal"
                                       :placeholder="$t('analyseKPISet.a48')"
                                        style="width:100px"
                                        v-if="record.show"
                                        :disabled="record.lock"
                                        @input.native="(e)=>monthProportionValue(e,record)"
                                    />
                                    <span v-else>{{ record.rateOfTotal }}</span>
                                </template>
                                <template slot="lock" slot-scope="text, record">
                                    <a-checkbox v-model="record.lock" v-if="record.show" ></a-checkbox>
                                    <span v-else>--</span>
                                </template>
                            </a-table >
                        </div>
                        <div style="width:45%">
                            <a-table 
                                :columns="monthColumns3" 
                                :data-source="monthData3" 
                                :pagination="false" 
                                size="middle" 
                                bordered
                                :rowKey="(record) => record.key"
                            >
                                <template slot="lastAimValue" slot-scope="text, record">
                                    <span>{{ $t('analyseKPISet.a13') }}</span>
                                    <span v-if="record.lastAimValue !== ''">{{ record.lastAimValue }}</span>
                                    <span v-else>----</span>
                                </template>
                                <template slot="lastActualValue" slot-scope="text, record">
                                    <span>{{ $t('analyseKPISet.a14') }}</span>
                                    <span v-if="record.lastActualValue !== ''">{{ record.lastActualValue }}</span>
                                    <span v-else>----</span>
                                </template>
                            </a-table>
                            <a-table 
                                :columns="monthColumns4" 
                                :data-source="monthData4" 
                                :pagination="false" 
                                size="middle" 
                                bordered
                                :rowKey="(record) => record.month + 1"
                                ref="table1"
                            >
                                <template slot="actualValue" slot-scope="text, record">
                                    <div :style="record.show ? 'height:32px;line-height:32px' : '' ">{{ record.actualValue }}</div>
                                </template>
                            </a-table>
                        </div>
                    </div>
                    <!-- 日类型表格 -->
                    <div class="dayTable" v-show="dateType == '3'">
                        <div class="tablevessel">
                            <div style="width:55%">
                                <a-table 
                                    :columns="dayColumns1" 
                                    :data-source="dayData1" 
                                    :pagination="false" 
                                    size="middle" 
                                    bordered 
                                    :rowKey="(record) => record.key"
                                >
                                    <template slot="aimValue" slot-scope="text, record">
                                        <span style="margin-right:10px">{{ month.format('YYYY-MM') }}</span>
                                        <span>{{ $t('analyseKPISet.a15') }}</span>
                                        <span v-if="record.aimValue !== ''">{{ record.aimValue }}</span>
                                        <span v-else>----</span>
                                    </template>
                                </a-table>
                                <a-table 
                                    :columns="dayColumns2" 
                                    :data-source="dayData2" 
                                    :pagination="false" 
                                    size="middle" 
                                    bordered
                                    :rowKey="(record) => record.key"
                                    ref="table2"
                                >
                                    <template slot="day" slot-scope="text, record">
                                        {{getDayWeek(text,record)}}
                                    </template>
                                    <template slot="aimValue" slot-scope="text, record">
                                            <!-- :min="0"  -->
                                        <a-input 
                                            v-model="record.aimValue"
                                            :placeholder="$t('analyseKPISet.a45')"
                                            style="width:140px"
                                            v-if="record.show"
                                            :disabled="record.lock"
                                            @input.native="(e)=>dayTargetValue(e,record)"
                                        />
                                        <span v-else>{{ record.aimValue }}</span>
                                    </template>
                                    <template slot="rateOfTotal" slot-scope="text, record">
                                            <!-- :min="0" 
                                            :max="100"  -->
                                        <a-input 
                                            v-model="record.rateOfTotal"
                                           :placeholder="$t('analyseKPISet.a48')"
                                            style="width:100px"
                                            v-if="record.show"
                                            :disabled="record.lock"
                                            @input.native="(e)=>dayProportionValue(e,record)"
                                        />
                                        <span v-else>{{ record.rateOfTotal }}</span>
                                    </template>
                                    <template slot="lock" slot-scope="text, record">
                                        <a-checkbox v-model="record.lock" v-if="record.show"></a-checkbox>
                                        <span v-else>--</span>
                                    </template>
                                </a-table >
                            </div>
                            <div style="width:45%">
                                <a-table 
                                    :columns="dayColumns3" 
                                    :data-source="dayData3" 
                                    :pagination="false" 
                                    size="middle" 
                                    bordered
                                    :rowKey="(record) => record.key"
                                >
                                  
                                    <template slot="lastAimValue" slot-scope="text, record">
                                        <span>{{ $t('analyseKPISet.a13') }}</span>
                                        <span v-if="record.lastAimValue !== ''">{{ record.lastAimValue }}</span>
                                        <span v-else>----</span>
                                    </template>
                                    <template slot="lastActualValue" slot-scope="text, record">
                                        <span>{{ $t('analyseKPISet.a14') }}</span>
                                        <span v-if="record.lastActualValue !== ''">{{ record.lastActualValue }}</span>
                                        <span v-else>----</span>
                                    </template>
                                </a-table>
                                <a-table 
                                    :columns="dayColumns4" 
                                    :data-source="dayData4" 
                                    :pagination="false" 
                                    size="middle" 
                                    bordered
                                    :rowKey="(record) => record.key"
                                    ref="table3"
                                >
                                    <template slot="day" slot-scope="text, record">
                                        {{getDayWeek(text,record)}}
                                    </template>
                                    <template slot="actualValue" slot-scope="text, record">
                                        <div :style="record.show ? 'height:32px;line-height:32px' : ''">{{ record.actualValue }}</div>
                                    </template>
                                </a-table>
                            </div>
                        </div>
                        <div class="tableFloor">
                            <div class="tableFloor-row" style="width:55%">
                                <div class="tableFloor-grid" style="width:50%">
                                    <span>{{ $t('analyseKPISet.a16') }}</span>
                                    <span>{{ ratio1 }}</span>
                                </div>
                                <div class="tableFloor-grid" style="width:50%">
                                    <span>{{ $t('analyseKPISet.a17') }}</span>
                                    <span>{{ ratio2 }}</span>
                                </div>
                            </div>
                            <div class="tableFloor-row" style="width:45%">
                                <div class="tableFloor-grid" syle="twidth:50%">
                                    <span>{{ $t('analyseKPISet.a18') }}</span>
                                    <span>{{ ratio3 }}</span>
                                </div>
                                <div class="tableFloor-grid" style="width:50%">
                                    <span>{{ $t('analyseKPISet.a19') }}</span>
                                    <span>{{ ratio4 }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="set-bar">
                    <div>
                        <a-button type="primary" v-show="dateType !== '1'" @click="dataAllocation">
                            {{ $t('analyseKPISet.a20') }}
                        </a-button>
                        <a-popover placement="top">
                            <template slot="content">
                                <p>{{ $t('analyseKPISet.a21') }}</p>
                                <p>{{ $t('analyseKPISet.a22') }}</p>
                                <p>{{ $t('analyseKPISet.a23') }}</p>
                                <p>{{ $t('analyseKPISet.a24') }}</p>
                                <p>{{ $t('analyseKPISet.a25') }}</p>
                                <p>{{ $t('analyseKPISet.a26') }}</p>
                                <p>{{ $t('analyseKPISet.a27') }}</p>
                            </template>
                            <a-button style="margin:0px 15px">{{ $t('analyseKPISet.a28') }}</a-button>
                        </a-popover>
                        <a-button type="primary" @click="openRuleManagement" style="margin-right:15px">{{ $t('analyseKPISet.a29') }}</a-button>
                    </div>
                    <div>
                        <a-checkbox v-model="autoEnable2" v-show="dateType == '2'" :disabled="monthData2.length == 0 || taskId===0 " style="margin-right:10px" @change="autoUpdate">
                            {{ $t('analyseKPISet.a30') }}
                        </a-checkbox>
                        <a-checkbox v-model="autoEnable3" v-show="dateType == '3'" :disabled="true" style="margin-right:10px">
                            {{ $t('analyseKPISet.a30') }}
                        </a-checkbox>
                        <a-checkbox v-model="executeEnable1" v-show="dateType == '1'" style="margin-right:20px">
                            {{ $t('analyseKPISet.a31') }}
                        </a-checkbox>
                        <a-checkbox v-model="executeEnable2" v-show="dateType == '2'" style="margin-right:20px">
                            {{ $t('analyseKPISet.a31') }}
                        </a-checkbox>
                        <a-checkbox v-model="executeEnable3" v-show="dateType == '3'" style="margin-right:20px">
                            {{ $t('analyseKPISet.a31') }}
                        </a-checkbox>
                        <a-button type="primary" v-show="dateType == '1'" :disabled="yearTableData.length == 0 || autoEnable2" @click="yearKPISetting ">
                            {{ $t('analyseKPISet.a32') }}
                        </a-button>
                        <a-button type="primary" v-show="dateType == '2'" :disabled="monthData2.length == 0 || autoEnable2" @click="monthKPISetting ">
                            {{ $t('analyseKPISet.a32') }}
                        </a-button>
                        <a-button type="primary" v-show="dateType == '3'" :disabled="dayData2.length == 0 || autoEnable3" @click="dayKPISetting ">
                            {{ $t('analyseKPISet.a32') }}
                        </a-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 规则管理对话框 -->
        <a-modal
            :title="$t('analyseKPISet.a29')"
            :visible="ruleManagementVisible"
            :centered="true"
            :width="750"
            @cancel="ruleManagementCancel"
            :destroyOnClose="true"
            v-drag
        >
            <!-- 页脚 -->
            <template slot="footer">
                <a-button type="primary" @click="ruleManagementCancel">
                    {{ $t('analyseKPISet.a33') }}
                </a-button>
            </template>
            <!-- 规则管理对话框内容 -->
            <div class="ruleManagement" v-loading="ruleManagementLoading" :element-loading-text="$t('alarm.a0')">
                <!-- 规则管理列表 -->
                <div class="ruleManagement-list">
                    <!-- 列表标题 -->
                    <div class="ruleManagement-title">
                        <span>{{ $t('analyseKPISet.a34') }}</span>
                        <a-button type="primary" ghost icon="plus" size="small" @click="ruleManagementAdd">{{ $t('analyseKPISet.a35') }}</a-button>
                    </div>
                    <!-- 列表容器 -->
                    <div class="ruleManagement-main">
                        <div class="emptyModule" v-show="yearRuleData.length === 0">
                            <a-empty />
                        </div>
                        <a-menu mode="inline" v-model="ruleListSelect" v-show="yearRuleData.length !== 0">
                            <a-menu-item v-for="(item,index) in yearRuleData" :key="index" @click="getMenuData(item)">
                                {{ item.name }}
                            </a-menu-item>
                        </a-menu>
                    </div>
                </div>
                <!-- 列表选择值修改区域 -->
                <div class="form">
                    <a-form-model ref="ruleForm" :model="form" :rules="rules">
                        <a-form-model-item ref="name" :label="$t('analyseKPISet.a36')" prop="name">
                            <a-input v-model="form.name" :disabled="ruleManagementDisabled.nameDisabled"/>
                        </a-form-model-item>
                        <a-form-model-item :label="$t('analyseKPISet.a37')">
                            <a-select v-model="form.monthListSelect" style="width: 120px;margin-right:15px" :disabled="ruleManagementDisabled.monthDisabled">
                                <a-select-option v-for="item in monthList" :value="item.no" :key="item.no">
                                    {{ item.text }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-form-model>
                    <a-button type="primary" style="margin-right:15px" :disabled="ruleManagementDisabled.setDisabled" @click="ruleManagementDataSet">
                        {{ $t('analyseKPISet.a32') }}
                    </a-button>
                    <a-button type="danger" :disabled="ruleManagementDisabled.deleteDisabled" @click="ruleManagementDataDelete">
                        {{ $t('analyseKPISet.a39') }}
                    </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import { KPISetDataInitialization,KPIAnnualRuleSetting,KPIAnnualRuleDelete,KPISetting,KPIAutoUpdate } from '../../api/energy'
import { EleResize } from '../../utils/esresize'
import moment from 'moment'

export default {
    components:{
        'my-PageTitle':PageTitle
    },
    data(){
        return{
            loading:false,
            pointList:[],                        // 侧边栏信号点列表数据   
            pointListCope:[],                    // 侧边栏信号点列表数据 -- 副本     
            menuSelect:[],                       // 侧边栏信号点选择值
            menuData:{},                         // 侧边栏信号点选择值的对象数据
            dateType:'1',                        // 年、月、日类型选择
            rptKinds:[],                         // 年、月、日列表
            yearData:[],                         // 财年列表
            fiscalYear:'',                       // 当前财年
            fiscalYearSelect:'',                 // 当前财年，默认选择年份
            yearRuleData:[],                     // 规则管理列表
            yearRuleSelect:undefined,            // 规则管理选择值
            strategy:99,
            monthList:[],
            monthListSelect:'1', 
            monthFiscalYear:undefined,
            comparisonFiscalYear:undefined,
            month:moment(),
            comparisonMonth:moment().subtract(1, 'years'),
            form:{
                name:'',
                monthListSelect:'1',
                no:undefined
            },
            yearColumns:[
                {
                    title: 'NO.',
                    dataIndex: 'no',
                    customRender: (text, record, index) =>{ return index + 1 },
                    width: 80,
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a40'),
                    dataIndex: 'year',
                    align: 'center',
                },
                {
                    title: this.$t('analyseKPISet.a41'),
                    dataIndex: 'actualValue',
                    align: 'center',
                },
                {
                    title: this.$t('analyseKPISet.a42'),
                    dataIndex: 'predictiveValue',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a43'),
                    dataIndex: 'baseRate',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a44'),
                    dataIndex: 'reductionAim',
                    align: 'center',
                    scopedSlots: { customRender: 'reductionAim' }
                },
                {
                    title: this.$t('analyseKPISet.a45'),
                    dataIndex: 'aimValue',
                    align: 'center',
                    scopedSlots: { customRender: 'aimValue' }
                },
                {
                    title: this.$t('analyseKPISet.a46'),
                    dataIndex: 'aimLastUpdate',
                    align: 'center'
                }
            ],
            yearTableData:[],          
            executeEnable1:false,
            executeEnable2:false,
            executeEnable3:false,
            autoEnable2:false,
            autoEnable3:false,
            monthColumns1:[
                {
                    title:()=>{ return this.monthFiscalYear + this.$t('analyseKPISet.a40') },
                    dataIndex: 'aimValue',
                    scopedSlots: { customRender: 'aimValue' },
                    align: 'center'
                }
            ],
            monthColumns2:[
                {
                    title: this.$t('analyseKPISet.a47'),
                    dataIndex: 'month',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a41'),
                    dataIndex: 'actualValue',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a30'),
                    dataIndex: 'autoValue',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a45'),
                    dataIndex: 'aimValue',
                    scopedSlots: { customRender: 'aimValue' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a48'),
                    dataIndex: 'rateOfTotal',
                    scopedSlots: { customRender: 'rateOfTotal' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a49'),
                    align: 'center',
                    dataIndex: 'lock',
                    scopedSlots: { customRender: 'lock' }
                }
            ],
            monthColumns3:[
                {
                    title:()=>{ return this.comparisonFiscalYear + this.$t('analyseKPISet.a40') },
                    colSpan: 2,
                    dataIndex: 'lastAimValue',
                    scopedSlots: { customRender: 'lastAimValue' },
                    align: 'center'
                },
                {
                    colSpan: 0,
                    dataIndex: 'lastActualValue',
                    scopedSlots: { customRender: 'lastActualValue' },
                    align: 'center'
                }
            ],
            monthColumns4:[
                {
                    title: this.$t('analyseKPISet.a41'),
                    dataIndex: 'actualValue',
                    scopedSlots: { customRender: 'actualValue' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a50'),
                    dataIndex: 'rateOfActual',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a45'),
                    dataIndex: 'aimValue',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a48'),
                    dataIndex: 'rateOfTotal',
                    align: 'center'
                }
            ],
            monthData1:[
                { key:1, aimValue:''}
            ],
            monthData1Copy:[],
            monthData2:[],
            monthData2Copy:[],
            monthData3:[
                { key:2, lastAimValue:'',lastActualValue:''}
            ],
            monthData4:[],
            monthLock:false,
            dayColumns1:[
                {
                    title:()=>{ return this.month.format('YYYY-MM') },
                    dataIndex: 'aimValue',
                    scopedSlots: { customRender: 'aimValue' },
                    align: 'center'
                }
            ],
            dayColumns2:[
                {
                    title: this.$t('analyseKPISet.a51'),
                    dataIndex: 'day',
                    scopedSlots: { customRender: 'day' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a41'),
                    dataIndex: 'actualValue',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a45'),
                    dataIndex: 'aimValue',
                    scopedSlots: { customRender: 'aimValue' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a48'),
                    dataIndex: 'rateOfTotal',
                    scopedSlots: { customRender: 'rateOfTotal' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a49'),
                    align: 'center',
                    scopedSlots: { customRender: 'lock' }
                }
            ],
            dayColumns3:[
                {
                    title:()=>{ return this.comparisonMonth.format('YYYY-MM') },
                    colSpan: 2,
                    dataIndex: 'lastAimValue',
                    scopedSlots: { customRender: 'lastAimValue' },
                    align: 'center'
                },
                {
                    colSpan: 0,
                    dataIndex: 'lastActualValue',
                    scopedSlots: { customRender: 'lastActualValue' },
                    align: 'center'
                }
            ],
            dayColumns4:[
                {
                    title: this.$t('analyseKPISet.a51'),
                    dataIndex: 'day',
                    scopedSlots: { customRender: 'day' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a41'),
                    dataIndex: 'actualValue',
                    scopedSlots: { customRender: 'actualValue' },
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a50'),
                    dataIndex: 'rateOfActual',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a45'),
                    dataIndex: 'aimValue',
                    align: 'center'
                },
                {
                    title: this.$t('analyseKPISet.a48'),
                    dataIndex: 'rateOfTotal',
                    align: 'center'
                }
            ],
            dayData1:[
                { key:1, aimValue:''}
            ],
            dayData1Copy:[],
            dayData2:[],
            dayData2Copy:[],
            dayData3:[
                { key:2, lastAimValue:'',lastActualValue:''}
            ],
            dayData4:[],
            dayLock:false,
            ruleManagementVisible:false,
            rules: {
                name: [
                    { required: true, message: this.$t('analyseKPISet.a52'), trigger: 'change' },
                ],
            },
            ruleManagementDisabled:{
                nameDisabled:true,
                monthDisabled:true,
                setDisabled:true,
                deleteDisabled:true
            },
            ruleManagementLoading:false,
            ruleListSelect:[],
            currentMonth: moment().format('M'),
            currentDay: moment().format('D'),
            currentYear: moment().format('YYYY'),
            weekendValue:'',              // 普通周末实际值；策略2基础数据
            weekdayValue:'',              // 普通工作日实际值；策略2基础数据
            holidayValue:'',              // 节假日实际值；策略3基础数据
            workdayValue:'',              // 工作日实际值；策略3基础数据
            lastWeekendValue:'',          // 对比日期普通周末实际值；策略2基础数据
            lastWeekdayValue:'',          // 对比日期普通工作日实际值；策略2基础数据
            lastHolidayValue:'',          // 对比日期节假日实际值；策略3基础数据
            lastWorkdayValue:'',          // 对比日期工作日实际值；策略3基础数据
            taskId:0,
            ruleType:'1',
            ruleNodeData:{}
        }
    },
    watch:{
        // 监听规则列表数据，指定列表第一个值未默认选中值
        yearRuleData:{
            handler(newValue){
                if(newValue !== 0){
                    this.yearRuleSelect = newValue[0].no
                }else{
                    return false
                }
            },
            deep:true
        },
        // 表格表头高度适应
        dateType:{
            handler(newValue){
                if(newValue == '2'){
                    this.$nextTick(()=>{
                        const dom = this.$refs.table.$el.getElementsByClassName('ant-table-thead')[0]
                        let listener = () => { 
                            const thead = this.$refs.table1.$el.getElementsByClassName('ant-table-thead')[0]
                            const Height = this.$refs.table.$el.getElementsByClassName('ant-table-thead')[0].clientHeight
                            thead.style = `height:${Height - 1}px`
                        }
                        EleResize.on(dom, listener)
                    })
                }else if(newValue == '3'){
                    this.$nextTick(()=>{
                        const dom = this.$refs.table2.$el.getElementsByClassName('ant-table-thead')[0]
                        let listener = () => { 
                            const thead = this.$refs.table3.$el.getElementsByClassName('ant-table-thead')[0]
                            const Height = this.$refs.table2.$el.getElementsByClassName('ant-table-thead')[0].clientHeight
                            thead.style = `height:${Height - 1}px`
                        }
                        EleResize.on(dom, listener)
                    })
                }
            },
            deep:true
        }
    },
    computed:{
        ratio1(){
            const aimValue = this.dayData1[0].aimValue
            if(aimValue == ''){
                return '----'
            }else if(aimValue !== 0){
                return `${(this.weekendValue/aimValue*100).toFixed(2)}%`
            }else{
                return '0%'
            }
        },
        ratio2(){
            const aimValue = this.dayData1[0].aimValue
            if(aimValue == ''){
                return '----'
            }else if(aimValue !== 0){
                return `${(this.holidayValue/aimValue*100).toFixed(2)}%`
            }else{
                return '0%'
            }
        },
        ratio3(){
            const lastActualValue = this.dayData3[0].lastActualValue
            if(lastActualValue == ''){
                return '----'
            }else if(lastActualValue !== 0){
                return `${(this.lastWeekendValue/lastActualValue*100).toFixed(2)}%`
            }else{
                return '0%'
            }
        },
        ratio4(){
            const lastActualValue = this.dayData3[0].lastActualValue
            if(lastActualValue == ''){
                return '----'
            }else if(lastActualValue !== 0){
                return `${(this.lastHolidayValue/lastActualValue*100).toFixed(2)}%`
            }else{
                return '0%'
            }
        },
    },
    mounted(){
        // 页面初始化数据获取
        this.loading = true
        const data = {
            action:0,
            clientId: Number(this.$store.getters.clientId),
            sitegrId: Number(this.$store.getters.sitegrId),
            siteId: Number(this.$route.query.id),
            tenantId:0,
            serviceType:8
        } 
        // console.log(data)
        KPISetDataInitialization(data)
        .then(res=>{
            console.log(res)
            const { data:{ fiscalYear,monthList,pointList,rptKinds,yearData,yearRuleData } } = res
            this.pointList = pointList
            this.pointListCope = pointList
            this.menuSelect = [ pointList[0].no ]
            this.menuData = pointList[0]
            this.fiscalYear = fiscalYear
            this.fiscalYearSelect = fiscalYear
            this.monthFiscalYear = fiscalYear
            this.comparisonFiscalYear = String(fiscalYear - 1)
            this.monthList = monthList
            this.rptKinds = rptKinds
            this.yearData = yearData
            this.yearRuleData = yearRuleData
            this.loading = false
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods:{
        moment,
        // 搜索框功能
        onChange(item){
            const value = item.target.value
            if(!value){
                const _arr = JSON.stringify(this.pointListCope) 
                const arr = JSON.parse(_arr)
                this.pointList = arr
            }else{
                const pointList = this.pointListCope
                const arr = pointList.filter(item =>  item.name.indexOf(value) > -1 )
                this.pointList = arr
            }
        },
        // 表格数据查询
        dataQuery(){
            const length = this.yearRuleData.length
            if(length == 0){
                this.$warning({
                    title: this.$t('analyseKPISet.a53'),
                    content: this.$t('analyseKPISet.a54'),
                    centered: true
                })
            }else{
                this.loading = true
                const data = {
                    action: 1,
                    clientId: this.$store.getters.clientId,
                    sitegrId: this.$store.getters.sitegrId,
                    siteId: this.$route.query.id,
                    tenantId: 0,
                    ruleId: this.yearRuleSelect,
                    kind: this.dateType,
                    pointType: this.menuData.category,
                    pointId: this.menuData.no 
                }
                const kind = this.dateType
                if(kind == '1'){
                    data.year = this.fiscalYearSelect
                }else if(kind == '2'){
                    data.year = this.monthFiscalYear
                    data.anyear = this.comparisonFiscalYear
                }else if(kind == '3'){
                    const _dateTime = JSON.stringify(this.month)
                    const dateTime = moment(JSON.parse(_dateTime)).format('YYYY/MM')
                    const _anDateTime = JSON.stringify(this.comparisonMonth)
                    const anDateTime = moment(JSON.parse(_anDateTime)).format('YYYY/MM')
                    data.year = dateTime.split('/')[0]
                    data.month = Number(dateTime.split('/')[1])
                    data.dateTime = dateTime
                    data.anDateTime = anDateTime
                    if(this.strategy == 99){
                        data.strategy = 0
                        data.calculate = 0
                    }else{
                        data.strategy = this.strategy
                        data.calculate = 1
                    }
                    
                }
                // console.log(data)
                KPISetDataInitialization(data)
                .then(res=>{
                    console.log(res)
                    this.loading = false
                    if (res.errorCode == "00") {
                        if(kind == '1'){
                            const { data:  { list,executeEnable ,autoEnable} } = res
                            this.yearTableData = list
                            this.executeEnable1 = executeEnable
                             this.autoEnable2 = autoEnable
                        }else if(kind == '2'){
                            const { data: { actualValue,aimValue,autoEnable,executeEnable,lastList,list,lastActualValue,lastAimValue,taskId } } = res
                            const monthData1 = [ { key:1, aimValue } ]
                            const monthData3 = [ { key:2, lastAimValue, lastActualValue } ]
                            this.monthData1 = monthData1
                            this.monthData3 = monthData3
                            this.autoEnable2 = autoEnable
                            this.executeEnable2 = executeEnable
                            const currentMonth = this.currentMonth
                            for(let i = 0;i<list.length;i++){
                                if(this.currentYear == this.monthFiscalYear){
                                    if(currentMonth > list[i].month && list[i].year == this.monthFiscalYear){
                                        list[i].lock = true
                                        list[i].show = false
                                        lastList[i].show = false
                                    }else {
                                        list[i].lock = false
                                        list[i].show = true
                                        lastList[i].show = true
                                    }
                                }else{
                                    list[i].show = false
                                    lastList[i].show = false
                                }
                            }
                            this.monthData2 = list
                            const _monthData2Copy = JSON.stringify(list)
                            const monthData2Copy = JSON.parse(_monthData2Copy)
                            this.monthData2Copy = monthData2Copy
                            this.monthData4 = lastList
                            this.taskId = taskId
                        }else if(kind == '3'){
                            const { data: { 
                                            aimValue,
                                            actualValue,
                                            lastAimValue,
                                            lastActualValue,
                                            weekendValue,
                                            weekdayValue,
                                            holidayValue,
                                            workdayValue,
                                            lastWeekendValue,
                                            lastWeekdayValue,
                                            lastHolidayValue,
                                            lastWorkdayValue,
                                            executeEnable,
                                            autoEnable,
                                            lastList,
                                            list
                                        }
                                    } = res
                            const dayData1 = [ { key:1, aimValue } ]
                            const dayData3 = [ { key:2, lastAimValue, lastActualValue } ]
                            this.dayData1 = dayData1
                            this.dayData3 = dayData3
                            const currentMonth = this.currentMonth
                            const currentYear = this.currentYear
                            const currentDay = this.currentDay
                            const _dateTime = JSON.stringify(this.month)
                            const dateTime = moment(JSON.parse(_dateTime)).format('YYYY/M')
                            const Year = dateTime.split('/')[0]
                            const month = dateTime.split('/')[1]
                            for(let i = 0;i < list.length;i++){
                                if(currentYear == Year){
                                    if(month < currentMonth){
                                        list[i].show = false
                                        lastList[i].show = false
                                    }else if(month == currentMonth){
                                        if(currentDay > list[i].day){
                                            list[i].lock = true
                                            list[i].show = false
                                            lastList[i].show = false
                                        }else{
                                            list[i].lock = false
                                            list[i].show = true
                                            lastList[i].show = true
                                        }
                                    }else if(month > currentMonth){
                                        list[i].lock = false
                                        list[i].show = true
                                        lastList[i].show = true
                                    }
                                }else{
                                    list[i].show = false
                                    lastList[i].show = false
                                }
                            }
                            this.dayData2 = list
                            const _dayData2Copy = JSON.stringify(list)
                            const dayData2Copy = JSON.parse(_dayData2Copy)
                            this.dayData2Copy = dayData2Copy
                            this.dayData4 = lastList
                            this.weekendValue = weekendValue
                            this.weekdayValue = weekdayValue
                            this.holidayValue = holidayValue
                            this.workdayValue = workdayValue
                            this.lastWeekendValue = lastWeekendValue
                            this.lastWeekdayValue = lastWeekdayValue
                            this.lastHolidayValue = lastHolidayValue
                            this.lastWorkdayValue = lastWorkdayValue
                            this.executeEnable3 = executeEnable
                            this.autoEnable3 = autoEnable
                        }
                    } else {
                        this.$warning({
                            title: res.msg,
                            centered: true
                        })
                    }
                })
                .catch(err=>{
                    this.loading = false
                    console.log(err)
                })
            }
        },
        // 侧边栏数据获取
        getSignalPointData(item){
            if(item.no !== this.menuData.no){
                this.menuData = item
                this.yearTableData = []
                this.monthData1 = [{ key:1, aimValue:''}]
                this.monthData2 = []
                this.monthData2Copy = []
                this.monthData3 = [{ key:2, lastAimValue:'',lastActualValue:''}]
                this.monthData4 = []
                this.dayData1 = [{ key:1, aimValue:''}]
                this.dayData1Copy = []
                this.dayData2 = []
                this.dayData2Copy = []
                this.dayData3 = [{ key:2, lastAimValue:'',lastActualValue:''}]
                this.dayData4 = []
            }
        },
        // 打开规则管理对话框
        openRuleManagement(){
            this.ruleManagementVisible = true
        },
        // 关闭规则管理对话框
        ruleManagementCancel(){
            this.ruleManagementVisible = false
            const form = {
                name:'',
                monthListSelect:'1'
            }
            const disabled = {
                nameDisabled:true,
                monthDisabled:true,
                setDisabled:true,
                deleteDisabled:true
            }
            this.form = form
            this.ruleManagementDisabled = disabled
        },
        // 规则管理添加操作
        ruleManagementAdd(){
            this.ruleType = '1'
            const form = {
                name:'',
                monthListSelect:'1',
                no: undefined
            }
            const disabled = {
                nameDisabled:false,
                monthDisabled:false,
                setDisabled:false,
                deleteDisabled:true
            }
            this.form = form
            this.ruleManagementDisabled = disabled
            this.ruleListSelect = []
        },
        // 获取规则列表选中数据
        getMenuData(item){
            this.ruleType = '2'
            this.ruleNodeData = item
            this.form.name = item.name
            this.form.monthListSelect = item.value
            this.form.no = item.no
            const disabled = {
                nameDisabled:false,
                monthDisabled:true,
                setDisabled:false,
                deleteDisabled:false
            }
            this.ruleManagementDisabled = disabled
        },
        // 规则列表数据设置
        ruleManagementDataSet(){
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.ruleManagementLoading = true
                    const data = {
                        action: 1,
                        clientId: this.$store.getters.clientId,
                        sitegrId: this.$store.getters.sitegrId,
                        siteId: this.$route.query.id,
                        tenantId: 0,
                        id:this.form.no,
                        month: this.form.monthListSelect,
                        name: this.form.name
                    }
                    KPIAnnualRuleSetting(data)
                    .then(res=>{
                        console.log(res)
                        if (res.errorCode == "03") {
                            this.$message.success(res.msg)
                            if(this.ruleType == '1'){
                                const data = {
                                    no:res.data.rule.no,
                                    value: this.form.monthListSelect,
                                    name: this.form.name
                                }
                                this.yearRuleData.push(data)
                                const disabled = {
                                    nameDisabled:false,
                                    monthDisabled:true,
                                    setDisabled:false,
                                    deleteDisabled:false
                                }
                                this.ruleManagementDisabled = disabled
                                this.ruleListSelect = [this.yearRuleData.length - 1]
                            }else{
                                this.ruleNodeData.name = this.form.name
                            }
                            
                        } else {
                            this.$message.error(res.msg)
                        }
                        this.ruleManagementLoading = false
                    })
                    .catch(err=>{
                        this.ruleManagementLoading = false
                        console.log(err)
                    })
                } else {
                    return false
                }
            })
        },
        // 规则列表数据删除
        ruleManagementDataDelete(){
            this.ruleManagementLoading = true
            const data = this.form.no
            KPIAnnualRuleDelete(data)
            .then(res=>{
                const form = {
                    name:'',
                    value:'1',
                    no:undefined
                }
                const disabled = {
                    nameDisabled:true,
                    monthDisabled:true,
                    setDisabled:true,
                    deleteDisabled:true
                }
                const ruleListSelect = this.ruleListSelect[0]
                this.ruleManagementLoading = false
                if (res.errorCode == "05" || res.errorCode == "00") {
                    this.$message.success(res.msg)
                    this.form = form
                    this.ruleManagementDisabled = disabled
                    this.yearRuleData.splice(ruleListSelect,1)
                } else {
                    this.$message.error(res.msg)
                }
            })
            .catch(err=>{
                console.log(err)
                this.ruleManagementLoading = false
            })
        },
        // 年能效KPI设置
        yearKPISetting(){
            
            const obj = this.yearTableData[0]
            const kind = this.dateType
            if(obj.reductionAim==='' ){
                this.$message.error('请输入节约目标值');
                return;
            }else if(isNaN(obj.reductionAim)){
                this.$message.error(this.$t('analyseKPISet.a56'));
                return;
            }
            if(obj.aimValue===''){
                this.$message.error('请输入目标值');
                 return;
            }else if(isNaN(obj.aimValue)){
                this.$message.error(this.$t('analyseKPISet.a59'));
                 return;
            }
            this.loading = true
            const data = {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId:0,
                ruleId: this.yearRuleSelect,
                serviceType:8,
                kind: this.dateType,
                year: this.fiscalYearSelect,	
                pointType: this.menuData.category,	
                pointId: this.menuData.no,
                executeEnable: this.executeEnable1,
                reductionAimList:[{
                    id:obj.id,
                    kind,
                    year:obj.year,
                    reductionAim:obj.reductionAim,
                    aimValue:obj.aimValue,
                }]
            }
            // console.log(data)
            KPISetting(data)
            .then(res=>{
                // console.log(res)
                this.loading = false
                if(res.errorCode == '03'){
                    this.$message.success(res.msg);
                    this.dataQuery();
                }else{
                    this.$message.error(res.msg)
                }
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 月能效KPI设置
        monthKPISetting(){
            const monthData2 = this.monthData2
            const reductionAimList = []
            let err = 0;
            let msg = '';
            let now = new moment();
            console.log(now.year(),now.month());
            for(let i = 0;i < monthData2.length;i++){
                if(now.year() > monthData2[i].year || (now.year()==monthData2[i].year && (now.month()+1) > monthData2[i].month)){
                    console.log(monthData2[i]);
                    reductionAimList.push({
                        id:monthData2[i].id,
                        kind:monthData2[i].kind,
                        year:monthData2[i].year,
                        month:monthData2[i].month,
                        aimValue:monthData2[i].aimValue,
                        aimRate:monthData2[i].rateOfTotal
                    })
                }else{

                    if(''==monthData2[i].aimValue ){
                        msg = '请输入目标值';
                        err = 1;
                        break;
                    }
                    if(''==monthData2[i].rateOfTotal ){
                        msg = '请输入目标值占比';
                        err = 1;
                        break;
                    } 
                    reductionAimList.push({
                        id:monthData2[i].id,
                        kind:monthData2[i].kind,
                        year:monthData2[i].year,
                        month:monthData2[i].month,
                        aimValue:monthData2[i].aimValue,
                        aimRate:monthData2[i].rateOfTotal
                    })
                }
            }
            if(err>0){

                this.$message.error(msg);
                return;
            }
            this.loading = true;
            
            const data = {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId:0,
                ruleId: this.yearRuleSelect,
                serviceType:8,
                kind: this.dateType,
                year: this.monthFiscalYear,	
                pointType: this.menuData.category,	
                pointId: this.menuData.no,
                executeEnable: this.executeEnable2,
                reductionAimList,
                cascade:false
            }
            KPISetting(data)
            .then(res=>{
                // console.log(res)
                this.loading = false
                if(res.errorCode == '03'){
                    this.$message.success(res.msg);
                    this.dataQuery();
                }else{
                    this.$message.error(res.msg)
                }
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 日能效KPI设置
        dayKPISetting(){
            this.loading = true
            const dayData2 = this.dayData2
            

            const reductionAimList = []
            let err = 0;
            let msg = '';

            for(let i = 0;i < dayData2.length;i++){
                if(''==dayData2[i].aimValue ){
                    msg = '请输入目标值';
                    err = 1;
                    break;
                }
                if(''==dayData2[i].rateOfTotal ){
                    msg = '请输入目标值占比';
                    err = 1;
                    break;
                } 
                reductionAimList.push({
                    id:dayData2[i].id,
                    kind:dayData2[i].kind,
                    year:dayData2[i].year,
                    month:dayData2[i].month,
                    day:dayData2[i].day,
                    aimValue:dayData2[i].aimValue,
                    aimRate:dayData2[i].rateOfTotal
                })
            }

            if(err>0){
                this.$message.error(msg);
                return;
            }
            this.loading = true;

            const data = {
                action:1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId:0,
                ruleId: this.yearRuleSelect,
                serviceType:8,
                kind: this.dateType,
                year: this.month.format('YYYY'),
                month: this.month.format('M'),
                pointType: this.menuData.category,	
                pointId: this.menuData.no,
                executeEnable: this.executeEnable2,
                reductionAimList,
            }
            // console.log(data)
            KPISetting(data)
            .then(res=>{
                console.log(res)
                this.loading = false
                if(res.errorCode == '03'){
                    this.$message.success(res.msg);
                    this.strategy = 99;
                    this.dataQuery();
                }else{
                    this.$message.error(res.msg)
                }
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 自动更新方法
        autoUpdate(item){
            if(this.taskId==0)
                return;
            this.loading = true
            const data = {
                action: 1,
                clientId: this.$store.getters.clientId,
                sitegrId: this.$store.getters.sitegrId,
                siteId: this.$route.query.id,
                tenantId: 0,
                id:this.taskId,
                autoEnable:item.target.checked
            }
            console.log(data)
            KPIAutoUpdate(data)
            .then(res=>{
                // console.log(res)
                this.loading = false
                if(res.errorCode == '03'){
                    this.$message.success(res.msg)
                    this.autoEnable2 = item.target.checked
                    if(this.dayData2 !== 0){ this.autoEnable3 = item.target.checked };
                    this.dataQuery();
                }else{
                    this.$message.error(res.msg)
                    this.autoEnable2 = !item.target.checked
                    if(this.dayData2 !== 0){ this.autoEnable3 = !item.target.checked }
                }
            })
            .catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        // 数据重置
        dataAllocation(){
            const dateType = this.dateType
            // console.log(dateType)
            if(dateType == '2'){
                const _data = JSON.stringify(this.monthData2Copy)
                const data = JSON.parse(_data)
                this.monthData2 = data
            }else if(dateType == '3'){
                const _data = JSON.stringify(this.dayData2Copy)
                const data = JSON.parse(_data)
                this.dayData2 = data
            }
        },
        // 年--目标值修改
        reductionAimValue(e,item){
            let value = e.target.value;
            console.log('reductionAimValue',value,isNaN(value),item);
            if('-'!=value && isNaN(value)){
                this.$message.error(this.$t('analyseKPISet.a56'));//this.$message.error('节约目标值格式错误，请输入数字。');
                if(item.id>0){
                    const rate = (item.baseValue - item.autoValue)/item.baseValue
                    item.reductionAim =  (rate*100).toFixed(2)
                    item.aimValue = item.autoValue
                } else {
                    item.reductionAim = 0.0;
                    item.aimValue = item.autoValue
                }
            }else if(value>100){
                this.$message.error(this.$t('analyseKPISet.a57'));//this.$message.error('节约目标值不能大于100%。');
               
                if(item.id>0){
                    const rate = (item.baseValue - item.autoValue)/item.baseValue;
                    console.log('rate',rate);
                    item.reductionAim = (rate*100).toFixed(2)
                    item.aimValue = item.autoValue
                } else {
                    item.reductionAim = 0.0;
                     item.aimValue = item.autoValue
                }
            }else if('-'==value ){
                this.$message.warning('请注意，您输入了负号"-"');
            }else if(''==value ){
                this.$message.error('请输入节约目标值');
            }else{
                const itemValue = item.baseValue
                const data = (100 - Number(value))/100*itemValue
                item.aimValue = data.toFixed(2)
            }
            
        },
        // 年--修改
        aimValueValue(e,item){
            let value = e.target.value; 
            console.log('aimValueValue',value,isNaN(value),item)

            if('-'===value || value<0){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a58'));//this.$message.error('目标值不能小于零。');
            } else if(isNaN(value)){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a59'));//this.$message.error('目标值格式错误，请输入数字。');
            } else if(''==value ){
                this.$message.error('请输入目标值');
            } else {
                if(item.baseValue && item.baseValue!=0){

                    const itemValue = item.baseValue
                    const data = 100 - (Number(value)/itemValue*100)
                    item.reductionAim = data.toFixed(2)
                }else{
                    item.reductionAim = 0;
                }
            }
        },
        // 月--目标值修改   
        monthTargetValue(e,item){
            let value = e.target.value; 
            console.log('monthTargetValue',value,item);
            if('-'===value || value<0){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a58'));//this.$message.error('目标值不能小于零。');
            } else if(isNaN(value)){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a59'));//this.$message.error('目标值格式错误，请输入数字。');
            }  else if(''==value ){
                this.$message.error('请输入目标值');
            } else {
                const importValue = Number(value)  
                const totalValue = this.monthData1[0].aimValue
                let balance = this.monthData1[0].aimValue
                let unlocked = -1
                const monthData = this.monthData2
                for(let i = 0;i < monthData.length;i++){
                    if(monthData[i].lock){
                        balance = balance - monthData[i].aimValue
                    }else{
                        unlocked = unlocked + 1
                    }
                }
                if(unlocked==0 || unlocked == -1)
                    return;
                const othvalue = balance - importValue;
                let _indexValue = othvalue/unlocked;
                const indexValue = _indexValue.toFixed(2)*1;
                let vtotal = 0;
                for(let i= 0;i<unlocked;i++){
                    vtotal+=indexValue;
                }
                const mod = (othvalue-vtotal).toFixed(2)*1;
                console.log('monthTargetValue mod',mod);
               

                const proportion = indexValue/totalValue*100;

                const modIndexValue = indexValue+mod;
                const modProportion = modIndexValue/totalValue*100;

                let lastUnLock = -1;
                for(let i = 0;i < monthData.length;i++){
                    if(importValue > balance){
                        if(!monthData[i].lock && item.month !== monthData[i].month){
                            monthData[i].aimValue = 0
                            monthData[i].rateOfTotal = 0
                        }else if(!monthData[i].lock && item.month == monthData[i].month){
                            item.aimValue = balance.toFixed(2)
                            item.rateOfTotal = (balance/totalValue*100).toFixed(2)
                        }
                    }else if(importValue < balance && unlocked !== 0){
                        if(!monthData[i].lock && item.month !== monthData[i].month){
                            monthData[i].aimValue = indexValue.toFixed(2)
                            monthData[i].rateOfTotal = proportion.toFixed(2)
                            lastUnLock = i;
                        }else if(!monthData[i].lock && item.month == monthData[i].month){
                            // item.aimValue = importValue.toFixed(2)
                            item.rateOfTotal = (importValue/totalValue*100).toFixed(2)
                        }
                    }else if(importValue < balance && unlocked == 0){
                        item.aimValue = balance.toFixed(2)
                        item.rateOfTotal = (balance/totalValue*100).toFixed(2)
                    }
                }
                if(lastUnLock>-1){
                    monthData[lastUnLock].aimValue = modIndexValue.toFixed(2);
                    monthData[lastUnLock].rateOfTotal = modProportion.toFixed(2);
                }
            }

        },
        // 月--目标值占比修改
        monthProportionValue(e,item){
            let value = e.target.value; 
            console.log('monthProportionValue',value,item);
            const totalValue = this.monthData1[0].aimValue

            if('-'===value || value<0){
                item.aimValue = item.autoValue;                
                this.$message.error(this.$t('analyseKPISet.a60'));//this.$message.error('目标值占比不能小于零。');
                 if(item.id>0){
                    const rate = item.autoValue/totalValue;
                    item.rateOfTotal = (rate*100).toFixed(2)
                } else {
                    item.rateOfTotal = 0.0;
                }
            } else if(isNaN(value)){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a61'));//this.$message.error('目标值占比格式错误，请输入数字。');


                if(item.id>0){
                    const rate = item.autoValue/totalValue;
                    item.rateOfTotal = (rate*100).toFixed(2)
                } else {
                    item.rateOfTotal = 0.0;
                }

            } else if(''==value ){
                this.$message.error('请输入目标值占比');
            } else {

                const importValue = Number(value)
               
                let balance = 100
                let unlocked = -1
                const monthData = this.monthData2
                for(let i = 0;i < monthData.length;i++){
                    if(monthData[i].lock){
                        balance = balance - monthData[i].rateOfTotal
                    }else{
                        unlocked = unlocked + 1
                    }
                }
                 if(unlocked==0 || unlocked == -1)
                    return;

                const othvalue = balance - importValue;
                let _indexValue = othvalue/unlocked
                const indexValue = _indexValue.toFixed(2)*1;
                let vtotal = 0;
                for(let i= 0;i<unlocked;i++){
                    vtotal+=indexValue;
                }
                const mod = (othvalue-vtotal).toFixed(2)*1;
                console.log('monthProportionValue mod',mod);

                const proportion = totalValue*(indexValue/100)

                const modIndexValue = indexValue+mod;
                const modProportion = totalValue*(modIndexValue/100)

                let lastUnLock = -1;
                for(let i = 0;i < monthData.length;i++){
                    if(importValue > balance){
                        if(!monthData[i].lock && item.month !== monthData[i].month){
                            monthData[i].aimValue = 0
                            monthData[i].rateOfTotal = 0
                        }else if(!monthData[i].lock && item.month == monthData[i].month){
                            item.aimValue = (Number(balance)/100*totalValue).toFixed(2)
                            item.rateOfTotal = Number(balance).toFixed(2)
                        }
                    }else if(importValue < balance && unlocked !== 0){
                        if(!monthData[i].lock && item.month !== monthData[i].month){
                            monthData[i].aimValue = proportion.toFixed(2)
                            monthData[i].rateOfTotal = indexValue.toFixed(2)
                            lastUnLock = i;
                        }else if(!monthData[i].lock && item.month == monthData[i].month){
                            item.aimValue = (importValue/100*totalValue).toFixed(2)
                            // item.rateOfTotal = importValue.toFixed(2) 
                        }
                    }else if(importValue < balance && unlocked == 0){
                        item.aimValue = (Number(balance)/100*totalValue).toFixed(2)
                        item.rateOfTotal = Number(balance).toFixed(2)
                    }
                }

                if(lastUnLock>-1){
                    monthData[lastUnLock].aimValue = modProportion.toFixed(2);
                    monthData[lastUnLock].rateOfTotal = modIndexValue.toFixed(2);
                }
            }
        },
        // 日--目标值修改 
        dayTargetValue(e,item){
            let value = e.target.value; 
            console.log('dayTargetValue',value,item);
            if('-'===value || value<0){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a58'));//this.$message.error('目标值不能小于零。');
            } else if(isNaN(value)){
                item.aimValue = item.autoValue;
               this.$message.error(this.$t('analyseKPISet.a59'));//this.$message.error('目标值格式错误，请输入数字。');
            } else if(''==value ){
                this.$message.error('请输入目标值');
            } else {
                const importValue = Number(value)  
                const totalValue = this.dayData1[0].aimValue
                let balance = this.dayData1[0].aimValue
                let unlocked = -1
                const monthData = this.dayData2
                for(let i = 0;i < monthData.length;i++){
                    if(monthData[i].lock){
                        balance = balance - monthData[i].aimValue
                    }else{
                        unlocked = unlocked + 1
                    }
                }
                if(unlocked==0 || unlocked==-1)
                    return;

                console.log(monthData)
                console.log(balance)

                const othvalue = balance - importValue;
                let _indexValue = othvalue/unlocked;
                const indexValue = _indexValue.toFixed(2)*1;
                let vtotal = 0;
                for(let i= 0;i<unlocked;i++){
                    vtotal+=indexValue;
                }
                const mod = (othvalue-vtotal).toFixed(2)*1;
                console.log('dayTargetValue mod',mod);

                const proportion = indexValue/totalValue*100

                const modIndexValue = indexValue+mod;
                const modProportion = modIndexValue/totalValue*100;

                let lastUnLock = -1;

                for(let i = 0;i < monthData.length;i++){
                    if(importValue > balance){
                        if(!monthData[i].lock && item.day !== monthData[i].day){
                            monthData[i].aimValue = 0
                            monthData[i].rateOfTotal = 0
                        }else if(!monthData[i].lock && item.day == monthData[i].day){
                            item.aimValue = balance.toFixed(2)
                            item.rateOfTotal = (balance/totalValue*100).toFixed(2)
                        }
                    }else if(importValue < balance && unlocked !== 0){
                        if(!monthData[i].lock && item.day !== monthData[i].day){
                            monthData[i].aimValue = indexValue.toFixed(2)
                            monthData[i].rateOfTotal = proportion.toFixed(2)
                        }else if(!monthData[i].lock && item.day == monthData[i].day){
                            // item.aimValue = importValue.toFixed(2)
                            item.rateOfTotal = (importValue/totalValue*100).toFixed(2)
                        }
                    }else if(importValue < balance && unlocked == 0){
                        item.aimValue = balance.toFixed(2)
                        item.rateOfTotal = (balance/totalValue*100).toFixed(2)
                    }
                }

                if(lastUnLock>-1){
                    monthData[lastUnLock].aimValue = modIndexValue.toFixed(2);
                    monthData[lastUnLock].rateOfTotal = modProportion.toFixed(2);
                }
            }
        },
        // 日--目标值占比修改
        dayProportionValue(e,item){
            let value = e.target.value; 
            console.log('dayTargetValue',value,item);
            if('-'===value || value<0){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a60'));//this.$message.error('目标值占比不能小于零。');
            } else if(isNaN(value)){
                item.aimValue = item.autoValue;
                this.$message.error(this.$t('analyseKPISet.a61'));//this.$message.error('目标值占比格式错误，请输入数字。');
            } else if(''==value ){
                this.$message.error('请输入目标值占比');
            } else {
                const importValue = Number(value)
                const totalValue = this.dayData1[0].aimValue
                let balance = 100
                let unlocked = -1
                const monthData = this.dayData2
                for(let i = 0;i < monthData.length;i++){
                    if(monthData[i].lock){
                        balance = balance - monthData[i].rateOfTotal
                    }else{
                        unlocked = unlocked + 1
                    }
                }
                if(unlocked==0 || unlocked==-1)
                    return;

                // const indexValue = (balance - importValue)/unlocked
                // const proportion = totalValue*(indexValue/100)

                const othvalue = balance - importValue;
                let _indexValue = othvalue/unlocked
                const indexValue = _indexValue.toFixed(2)*1;
                let vtotal = 0;
                for(let i= 0;i<unlocked;i++){
                    vtotal+=indexValue;
                }
                const mod = (othvalue-vtotal).toFixed(2)*1;
                console.log('monthProportionValue mod',mod);

                const proportion = totalValue*(indexValue/100)

                const modIndexValue = indexValue+mod;
                const modProportion = totalValue*(modIndexValue/100)

                let lastUnLock = -1;

                for(let i = 0;i < monthData.length;i++){
                    if(importValue > balance){
                        if(!monthData[i].lock && item.day !== monthData[i].day){
                            monthData[i].aimValue = 0
                            monthData[i].rateOfTotal = 0
                        }else if(!monthData[i].lock && item.day == monthData[i].day){
                            item.aimValue = (Number(balance)/100*totalValue).toFixed(2)
                            item.rateOfTotal = Number(balance).toFixed(2)
                        }
                    }else if(importValue < balance && unlocked !== 0){
                        if(!monthData[i].lock && item.day !== monthData[i].day){
                            monthData[i].aimValue = proportion.toFixed(2)
                            monthData[i].rateOfTotal = indexValue.toFixed(2)
                        }else if(!monthData[i].lock && item.day == monthData[i].day){
                            item.aimValue = (importValue/100*totalValue).toFixed(2)
                            // item.rateOfTotal = importValue.toFixed(2) 
                        }
                    }else if(importValue < balance && unlocked == 0){
                        item.aimValue = (Number(balance)/100*totalValue).toFixed(2)
                        item.rateOfTotal = Number(balance).toFixed(2)
                    }
                }

                 if(lastUnLock>-1){
                    monthData[lastUnLock].aimValue = modProportion.toFixed(2);
                    monthData[lastUnLock].rateOfTotal = modIndexValue.toFixed(2);
                }
            }
        },
        getDayWeek(text,record){
            // console.log('getDayWeek',record);
            let mom = new moment(record.dateTime);
            // console.log('mom',mom,mom.format('ddd'),mom.format('D'));
            let msg = mom.format('D')+"("+mom.format('ddd')+")";
            return msg;
        }
    }
}
</script>

<style scoped>
div /deep/.ant-modal-header{
    background-color: #7682CE;
}
#analyseKPISet{
    width: 100%;
    height: 100%;
}
.page-title{
    height: 50px;
}
.content{
    display: flex;
    width: 100%;
    height: calc(100% - 50px);
    padding: 10px 20px;
}
.sider{
    width: 300px;
    margin-right: 15px;
}
.menu{
    height: calc(100% - 32px);
    overflow: auto;
}
.main{
    width: calc(100% - 315px);
}
.query-bar{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.dateSelect{
    display: flex;
}
.dateType{
    position: relative;
}
.tableFloor{
    height: 40px;
    padding-right: 18px;
}
.tableFloor-grid{
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #e8e8e8;
    border-radius: 3px;
}
.tableFloor-row{
    display: flex;
}
.date-select{
    display: flex;
    align-items: center;
    width: 800px;
    position: absolute;
}
.date-select-font{
    font-size: 14px;
    color: rgba(12, 12, 12, 0.65);
    margin-right: 5px;
}
.tableDataSet{
    height: calc(100% - 94px);
    overflow: auto;
}
.emptyModule{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.emptyModule-font{
    font-size: 18px;
    color: #7682CE;
}
.set-bar{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}
.table{
    display: flex;
}
.dayTable{
    height: 100%;
    overflow: hidden;
}
.tablevessel{
    display: flex;
    height: calc(100% - 40px);
    overflow: auto;
}
.tableFloor{
    display: flex;
    font-size: 12px;
}
.dateFont{
    font-size: 14px;
    margin-right: 5px;
    color: rgba(12, 12, 12, 0.65);
}
.ruleManagement{
    display: flex;
}
.ruleManagement-list{
    width: 350px;
    border: 1px solid rgba(12, 12, 12, 0.15);
    border-radius: 3px;
    margin-right: 35px;
}
.ruleManagement-title{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(12, 12, 12, 0.15);
    padding: 10px;
}
.ruleManagement-main{
    height: 300px;
    overflow: auto;
}
.form{
    width: 320px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>